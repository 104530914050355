import React, {useContext, useState} from "react";
import {AppContext} from "../AppContext.js";
import {log} from '../services/utils.js';
import {apiCall} from "../services/apiCall.js";
import {AppModal} from "../components/AppModal.js";
import { useTranslation } from "react-i18next";

export function ChannelReindexModal(props) {
	
	const { t } = useTranslation();
    const appContext = useContext(AppContext);
    const {channelId, setReindexing, closeAction, videoCount} = props;
	const [busy, setBusy] = useState(false);

    const handleClose = function () {
    	appContext.closeModal();
    	closeAction();
    }

    const reindexNow = function() {
    	setReindexing(true);
    	setBusy(true);
		let _call = apiCall('/api.php?action=channel/reindex/' + channelId, {});
		//let _call = apiCall('/channel/reindex', {channelId: channelId});
		_call.then(function (json) {
			log('channelPage reindexChannel got ', json)
			if (json.status === "ok") {
				log("reindexing result: ", json);
			}
		}).catch(function (e) {
				log("reindexChannel error in ChannelPage! ", e);
		}).then(function () {
			setBusy(false);
			setReindexing(false);
			handleClose();
		});
    }

    let message = t('channel.reindex.message');
	if (videoCount > 500) {
		message += "<p>" + t('channel.reindex.message-long') + "</p>";
	}

  	return (
  		<AppModal 
  			title={t('channel.reindex.title')} 
  			message={message} 
  			okAction={reindexNow}
  			okLabel={t('channel.reindex.start')}
  			busy={busy}
  		/>
  	);
}
