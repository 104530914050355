import {log} from '../services/utils.js';
import {getConfig} from '../config.js';

export function apiCall(path, params, formData = false) {
	const url = getConfig('backendServer') + '/api' + path;
	log("Calling ", url, params);
	var _h = {};
    var _f = formData ? formData : new FormData();

// TODO: NO FUNCIONA!
    Object.keys(params).forEach((key) => _f.append(key, params[key]));
	log("call with ", _f, params);
    return window.fetch(url, {
            headers: _h,
            mode: 'cors',
            method: 'POST',
            body: _f,
        })
        .then(
            function(response) {
                log("apiCall response for ", url, response);
                if (response.status !== 200) {
                	// 401 / 402
                    throw '';
                }
                try {
                	return response.json();
                } catch (e) {
                  log('apiCall - Invalid JSON for URL ', url);
                  throw e;
                }
            }
        );
}

