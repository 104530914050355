import React from "react";
import './ChannelImage.scss';

export function ChannelImage(props) {
	let data = props.data;
	return (
		<div className="channelImage">
			{data.channelImage &&
				<img src={"data:image/jpg;charset=utf8;base64," + data.channelImage} alt="" />
			}
			{!data.channelImage &&
				<img src={data.thumbnailUrl} alt="" referrerPolicy="no-referrer" />
			}
		</div>

	);
}
