import React, {useContext} from "react";
import {AppContext} from "../AppContext.js";
import './RunningPanel.scss';
import { Oval } from 'react-loading-icons'

export function RunningPanel(props) {
	const { type } = props;
	const appContext = useContext(AppContext);

	return (
		<div className={"runningPanel" + (type ? " runningPanel-" + type : "")}>
			<Oval className="appButtonIcon" stroke={appContext.DM ? "white" : "black"} strokeOpacity={1} speed={1} />
		</div>
	);
}
