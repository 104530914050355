import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {formatInteger, isIntegerValue, log} from '../services/utils.js';
import {apiCall} from "../services/apiCall.js";
import {BigMessage} from "../components/BigMessage.js";
import {RunningPanel} from "../components/RunningPanel.js";
/*import ReactTooltip from 'react-tooltip';*/
import { useTranslation } from "react-i18next";
import {ChannelImage} from "../pieces/ChannelImage.js";
import './ChannelsPage.scss';

export function ChannelsPage(props) {
	
	const { t, i18n } = useTranslation();
	const [running, setRunning] = useState(false);
	const [channelsList, setChannelsList] = useState([]);
	const [orderField, setOrderField] = useState("title");
	const [searchTerm, setSearchTerm] = useState(false);
	const [orderType, setOrderType] = useState("ASC");
	/*const [tooltip, showTooltip] = useState(false);*/
	
	useEffect(() => {
		loadData();
  	}, []);
	

	function sortChannelsList(list) {
		
		if (orderField === "datePublished") {
			orderField = "datePublishedTs";
		}

		list.sort((a, b) => {
			let aVal = a[orderField];
			let bVal = b[orderField];
			if (isIntegerValue(aVal)) {aVal = parseInt(aVal)}
			if (isIntegerValue(bVal)) {bVal = parseInt(bVal)}
			let returnValue = 0;
			if (orderType === 'ASC') {
				returnValue = aVal > bVal ? 1 : -1;
			} else {
				returnValue = aVal < bVal ? 1 : -1;
			}
			return returnValue;
		});
		if (searchTerm) {
			list = list.filter((item) => {
				return item['title'].toUpperCase().includes(searchTerm.toUpperCase())
		});
		}
		return list;
	}
	function loadData() {
		setRunning(true);
		let _call = apiCall('/api.php?action=channel/list', {});
		//let _call = apiCall('/channel/list', {});
		_call.then(function (json) {
			log('getChannelsList got ', json);
			if (json.data) {
				setChannelsList(sortChannelsList(json.data));
			}
		}).catch(function (e) {
				log("loadData error in ChannelsPage! ", e);
		}).then(function () {
			setRunning(false);
		});
	}

	if (running) {
		return (
			<RunningPanel type="full" />
		);
	}

	if (channelsList.length === 0) {
		return (
			<BigMessage text={t('noChannels')} />
		);
	}

  	return (
  		<div className="channelsPage">
	  		{/* tooltip && <ReactTooltip effect="solid" /> */}
			<div className="list">
			{channelsList.map((item) => {
				return (
					<Link to={"/channel/" + item.channelId} className="listItem" key={item.idChannel}>
						<div className="channelTitle appRow">
							<div className="appColumn">
								<ChannelImage data={item} />
							</div>
							<div className="appColumn">
									<div className="bigText">{item.title}</div>
									<div className="mediumText channelStatistics">
										<div>{formatInteger(item.ytVideoCount, i18n.language)} {t('channel.videos')}</div>
										{item.subscriberCount && <div>{formatInteger(item.subscriberCount, i18n.language)} {t('channel.subscribers')}</div>}
										{item.viewCount && <div>{formatInteger(item.viewCount, i18n.language)} {t('channel.views')}</div>}
									</div>
							</div>
						</div>
						<div className="appRow small-text">
							<div>{item.description}</div>
						</div>
					</Link>
				)
			})
			}
			</div>
		</div>
  	);
}

