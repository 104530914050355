import React from "react";
import { useTranslation } from "react-i18next";

export function SearchVideosForm(props) {
	//https://www.youtube.com/results?search_query=lain&sp=CAM%253D
	const { t } = useTranslation();

	const handleSearchTerm = (e) => {
		e.preventDefault();
		props.setSearchTerm(e.target.value);
	}

	const clearSearchTerm = (e) => {
		e.preventDefault();
		props.setSearchTerm("");
	}	

	return (
		<div>
			<form>
				<label>
					{t('search.label')}
					<input 
						type="text" 
						name="searchTerm" 
						value={props.searchTerm} 
						onChange={e => {handleSearchTerm(e)}} 
						placeholder={t("search.placeholder")}
					/>
				</label>
				<button onClick={clearSearchTerm}>X</button>
			</form>
		</div>
	);
}