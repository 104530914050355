import React, {useContext} from "react";
import {AppContext} from "../AppContext.js";
import LanguageSwitcher from "../components/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import './SettingsPage.scss';

export function SettingsPage(props) {
	
	const { t } = useTranslation();
    const appContext = useContext(AppContext);    

  	return (
  		<div className="settingsPage appPage">
  			<div className="appRow">
  				<div className="appColumn">{t('settings.currentViewMode')} <span>{appContext.DM ? t('dark') : t('light')}</span></div>
  				<div className="appColumn">
					<a
					  href="#"
					  className="appLinkButton"
					  target="_self"
					  rel="noopener noreferrer"
					  onClick={appContext.switchUiMode}
					>
					  {appContext.DM ? t('settings.switchToLightMode') : t('settings.switchToDarkMode')}
					</a>
				</div>
			</div>
			<div className="appRow">
  				<div className="appColumn">{t('settings.currentLanguage')}</div>
  				<div className="appColumn"><LanguageSwitcher /></div>
			</div>
		</div>
  	);
}

