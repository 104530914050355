import React from "react";
import './ErrorMessage.scss';

export function ErrorMessage(props) {
	
	const text = props.text;

	if (text) {
		return (
			<div className="errorMessage">{text}</div>
		);
	}
}

