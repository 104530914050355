import React from "react";
import {useSearchParams} from "react-router-dom";
import { useTranslation } from "react-i18next";

export function SortVideosForm(props) {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	
	let orderFieldValue = searchParams.get("of") || props.videoOrderField;
	let orderTypeValue = searchParams.get("ot") || props.videoOrderType;

	const handleOrderFieldChange = (e) => {
		e.preventDefault();
		props.setVideoOrderField(e.target.value);
	}
	const handleOrderTypeChange = (e) => {
		e.preventDefault();
		props.setVideoOrderType(e.target.value);		
	}

	return (
			<form>
			<label>{t('order.by')}</label>
			<select name="orderField" id="orderField" value={orderFieldValue} onChange={e => {handleOrderFieldChange(e)}}>
				<option value="title">{t('order.by-title')}</option>
				<option value="description">{t('order.by-description')}</option>
				<option value="datePublished">{t('order.by-datePublished')}</option>
				<option value="duration">{t('order.by-duration')}</option>
				<option value="viewCount">{t('order.by-viewCount')}</option>
				<option value="likeCount">{t('order.by-likeCount')}</option>
			</select>
			<select name="orderType" id="orderType" value={orderTypeValue} onChange={e => {handleOrderTypeChange(e)}}>
				<option value="ASC">{t('order.' + orderFieldValue + '.asc')}</option>
				<option value="DESC">{t('order.' + orderFieldValue + '.desc')}</option>
			</select>
		</form>
	);
}