import React, {useState} from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {getConfig} from './config.js';
import {AppContext} from "./AppContext.js";
import {MainHeader} from './components/MainHeader.js';
import {ContextMenu} from './components/ContextMenu.js';
import {ChannelsPage} from './layouts/ChannelsPage.js';
import {ChannelPage} from './layouts/ChannelPage.js';
import {AddChannelPage} from './layouts/AddChannelPage.js';
import {AddChannelNow} from './layouts/AddChannelNow.js';
import {SettingsPage} from './layouts/SettingsPage.js';
import {Footer} from './components/Footer.js';
import "bootstrap/dist/css/bootstrap.min.css";
import './App.scss';
import './assets/common.scss';

export default function App(props) {

	var startWithDM = getConfig('startWithDarkMode');
	if (window.matchMedia) {
	  // Check if the dark-mode Media-Query matches
	  if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
	    startWithDM = true;
	  } else {
	    startWithDM = false;
	  }
	}

	const [DM, setDM] = useState(startWithDM);
	const [currentPage, setCurrentPage] = useState('channelsPage');
	const [currentModal, setCurrentModal] = useState(false);

	const switchUiMode =  () => setDM(!DM);

	const openModal = (d) => {
        setCurrentModal(d);
    }

    const closeModal = () => {
        setCurrentModal(false);
    }

	


	return (
		<AppContext.Provider value={{
			DM: DM,
			switchUiMode: switchUiMode,
			p: currentPage,
			setPage: setCurrentPage,
			openModal: openModal,
			closeModal: closeModal,
		}}>
		<Router>
			<div className={"App" + (DM ? " darkMode" : "")}>
			<div>
				<MainHeader />
				<div className="mainBody">
					<ContextMenu />

				{/*
					A <Routes> looks through all its children <Route>
					elements and renders the first one whose path
					matches the current URL. Use a <Routes> any time
					you have multiple routes, but you want only one
					of them to render at a time
				*/}
					<div className="mainPanel">
						<Routes>
							<Route exact path="/" element={<ChannelsPage />} /> 
							<Route path="/channels" element={<ChannelsPage />} /> 
							<Route path="/channel/:channelId" element={<ChannelPage />} /> 
							<Route path="/add-channel" element={<AddChannelPage />} /> 
							<Route path="/add-channel/:channelId" element={<AddChannelNow action="add" />} /> 
							<Route path="/settings" element={<SettingsPage />} /> 
						</Routes>
					</div>
				</div>
			</div>
			{currentModal}
			<Footer />
			</div>
		</Router>
		</AppContext.Provider>
	);
}

