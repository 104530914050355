// info de https://phrase.com/blog/posts/localizing-react-apps-with-i18next/#Library_versions
// TODO: Crear fitxers a part amb les traduccions

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {getConfig} from '../config.js';

const resources = {
	en: {
		translation: {
			running: "Running...",
			loading: "Loading...",
			reindexing: "Updating...",
			noChannels: "No channels found",
			noChannelsFound: "No channels found",
			button: {
				"close": "Close",
				"ok": "OK",
			},
			menu: {
				home: "Home",
				channels: "Channels",
				addChannel: "Add channel",
				settings: "Settings",
			},
			openYT: "Open in YouTube",
			channel: {
				videos: "videos",
				subscribers: "subscribers",
				views: "views",
				datePublished: "On YouTube since:",
				dateLastIndexed: "Last updated:",
				ytVideoCount: "Number of videos on YouTube:",
				subscriberCount: "Number of subscribers:",
				viewCount: "Number of views:",
				reindexChannel: "Refresh this channel now",
				indexChannel: "Add this channel to TrobaTube",
				index: {
					title: "Get channel information & video list",
					message: "Are you sure you want to add this channel to TrobaTube with the information from YouTube? This may take some seconds or minutes depending on the number of videos.",
					"message-long": "<br/>This channel has more than 500 videos. Only the last 500 videos will be added. <br/> If you are the owner of this channel and want to see all the videos, please contact us at <a mailto='hello@trobatube.com' target='_blank'>hello@trobatube.com</a>",
					start: "Start now",
					error: "Something went wrong. Please, contact support service or try again later."
				},
				reindex: {
					title: "Refresh channel information & video list",
					message: "Are you sure you want to refresh this channel with the information from YouTube? This may take some seconds or minutes depending on the number of videos.",
					"message-long": "<br/>This channel has more than 500 videos. Only the last 500 videos will be refreshed. <br/> If you are the owner of this channel and want to see all the videos, please contact us at <a mailto='hello@trobatube.com' target='_blank'>hello@trobatube.com</a>",
					start: "Start refreshing now",
				},
			},
			mode: {
				dark: "Dark mode",
				light: "Light mode",
			},
			dark: "Dark",
			light: "Light",
			video: {
				datePublished: "Published on ",
				views: "views",
				likes: "likes",
				reindexVideo: "Refresh this video now",
				reindex: {
					title: "Refresh",
					message: "Are you sure you want to update this video information with the information from YouTube?",
				}
			},
			order: {
				by: "Order by",
				asc: "Ascending",
				desc: "Descending",
				title: {
					asc: "A-Z",
					desc: "Z-A",
				},
				description: {
					asc: "A-Z",
					desc: "Z-A",
				},
				datePublished: {
					asc: "Older first",
					desc: "Newer first",
				},
				duration: {
					asc: "Shorter first",
					desc: "Longer first",
				},
				viewCount: {
					asc: "Less views first",
					desc: "More views first",
				},
				likeCount: {
					asc: "Less popular first",
					desc: "More popular first",
				},
				"by-title": "Title",
				"by-description": "Description",
				"by-datePublished": "Date published",
				"by-duration": "Duration",
				"by-viewCount": "Views",
				"by-likeCount": "Likes",
			},
			search: {
				'open-advanced': "Advanced search",
				'open-simple': "Simple search",
				label: "",
				button: "Search",
				placeholder: "Search...",
				placeholder2: "Insert value",
				placeholderNumber: "Insert number",
				placeholderTime: "Format HH:MM:SS",
				placeholderDate: "Format YYYY-MM-DD",
				clear: "Clear search",
				go: "Search",
				numFound: "Found {{1}} videos",
				numFoundTotal: "Found {{1}} videos (total: {{2}})",
			},
			serchOp: {
				containing: "Containing",
				notContaining: "Not containing",
				starting: "Starting with",
				ending: "Ending with",
				exact: "Equal to",
				bigger: "More than",
				smaller: "Less than",
				before: "Before",
				after: "After",
				between: "Between",
				and: "and",
			},
			addChannelPage: {
				indexThisChannel: "Add this channel",
				alreadyIndexed: "This channel is already on TrobaTube (last updated on {{1}})",
				gotoChannelPage: "Go to channel page",
				getMoreData: "Get more information from YouTube",
			},
			searchYTChannel: {
				intro: "Please enter the name or the ID of the channel that you want to add. We will look for this channel on YouTube.",
				button: "Search",
				placeholder: "Channel name or ID",
				noChannels: "No channels found. Please try with another ID or text",
			},
			settings: {
				currentViewMode: "Current view mode:",
				currentLanguage: "Current language:",
				switchToLightMode: "Switch to Light mode",
				switchToDarkMode : "Switch to Dark mode",
			},
			footer: {
				text1: "Last update: September 2022",
				text2: "TrobaTube",
				text3: "Contact us at <a mailto='hello@trobatube.com' target='_blank'>hello@trobatube.com</a>",
			},
		},
	},
	es: {
		translation: {
			running: "En proceso...",
			loading: "Cargando...",
			noChannels: "No se han encontrado canales",
			noChannelsFound: "No se han encontrado canales",
			button: {
				"close": "Cerrar",
				"ok": "OK",
			},
			menu: {
				home: "Inicio",
				channels: "Canales",
				addChannel: "Añadir canal",
				settings: "Configuración",
			},
			openYT: "Abrir en YouTube",
			channel: {
				videos: "vídeos",
				subscribers: "suscriptores",
				views: "visualizaciones",
				datePublished: "En YouTube desde:",
				dateLastIndexed: "Última actualización:",
				ytVideoCount: "Número de vídeos en YouTube:",
				subscriberCount: "Número de suscriptores:",
				viewCount: "Número de visualizaciones:",
				indexChannel: "Añadir este canal a TrobaTube",
				index: {
					title: "Obtener información y lista de vídeos",
					message: "¿Seguro que quieres añadir este canal a TrobaTube con la información que hay en YouTube? Este proceso puede tardar algunos segundos o minutos según el número de vídeos del canal.",
					"message-long": "<br/>Este canal tiene más de 500 vídeos. Solo se añadirán los 500 vídeos más recientes.<br/>Si eres el propietario del canal y quieres ver todos los vídeos, por favor, ponte en contacto nosotros en <a mailto='hola@trobatube.com' target='_blank'>hola@trobatube.com</a>",
					start: "Empezar",
					error: "Algo fue mal. Por favor, contacta con soporte o vuélvelo a intentar más tarde."
				},
				reindexChannel: "Actualizar este canal ahora",
				reindex: {
					title: "Actualizar información y lista de vídeos",
					message: "¿Seguro que quieres actualizar este canal con la información que hay ahora en YouTube? Este proceso puede tardar algunos segundos o minutos según el número de vídeos del canal.",
					"message-long": "<br/>Este canal tiene más de 500 vídeos. Solo se actualizarán los 500 vídeos más recientes.<br/>Si eres el propietario del canal y quieres ver todos los vídeos, por favor, ponte en contacto nosotros en <a mailto='hola@trobatube.com' target='_blank'>hola@trobatube.com</a>",
					start: "Empezar la actualización ahora",
				},
			},
			mode: {
				dark: "Modo oscuro",
				light: "Modo claro",
			},
			dark: "Oscuro",
			light: "Claro",
			video: {
				datePublished: "Publicado el ",
				views: "visualizaciones",
				likes: "likes",
				reindexVideo: "Actualizar este vídeo ahora",
				reindex: {
					title: "Actualizar",
					message: "¿Seguro que quieres actualizar la información de este vídeo con la información que hay ahora en YouTube?",
				}
			},
			order: {
				by: "Ordenar por",
				asc: "Creciente",
				desc: "Decreciente",
				title: {
					asc: "A-Z",
					desc: "Z-A",
				},
				description: {
					asc: "A-Z",
					desc: "Z-A",
				},
				datePublished: {
					asc: "Más antiguos primero",
					desc: "Más recientes primero",
				},
				duration: {
					asc: "Más cortos primero",
					desc: "Más largos primero",
				},
				viewCount: {
					asc: "Con menos visualizaciones primero",
					desc: "Con más visualizaciones primero",
				},
				likeCount: {
					asc: "Menos populares primero",
					desc: "Más populares primero",
				},
				"by-title": "Título",
				"by-description": "Descripción",
				"by-datePublished": "Fecha de publicación",
				"by-duration": "Duración",
				"by-viewCount": "Visualizaciones",
				"by-likeCount": "Likes",
			},
			search: {
				'open-advanced': "Búsqueda avanzada",
				'open-simple': "Búsqueda simple",
				label: "",
				button: "Buscar",
				placeholder: "Buscar...",
				placeholder2: "Texto a buscar",
				placeholderNumber: "Indica un número",
				placeholderTime: "Formato HH:MM:SS",
				placeholderDate: "Formato AAAA-MM-DD",
				clear: "Limpiar campos",
				go: "Buscar",
				numFound: "Encontrados {{1}} vídeos",
				numFoundTotal: "Encontrados {{1}} vídeos (total: {{2}})",
			},
			serchOp: {
				containing: "Con el texto",
				notContaining: "Sin el texto",
				starting: "Empezando por",
				ending: "Acabando por",
				exact: "Exactamente igual a",
				bigger: "Más de",
				smaller: "Menos de",
				before: "Antes de",
				after: "Despés de",
				between: "Entre",
				and: "y",
			},
			addChannelPage: {
				indexThisChannel: "Añade este canal",
				alreadyIndexed: "Este canal ya está en TrbaTube (indexado el {{1}})",
				gotoChannelPage: "Ir a la página del canal",
				getMoreData: "Obtener más información YouTube",
			},
			searchYTChannel: {
				intro: "Indica el nombre o el ID del canal que quieres añadir. Buscaremos este canal en YouTube",
				button: "Buscar",
				placeholder: "Nombre o ID del canal",
				noChannels: "No se han encontrado canales con este criterio de búsqueda",
			},
			settings: {
				currentViewMode: "Modo actual:",
				currentLanguage: "Idioma actual:",
				switchToLightMode: "Cambiar a modo claro",
				switchToDarkMode : "Cambiar a modo oscuro",
			},
			footer: {
				text1: "Última actualización: Septiembre 2022",
				text2: "TrobaTube",
				text3: "Contacta con nosotros en <a mailto='hola@trobatube.com' target='_blank'>hola@trobatube.com</a>",
			},
		},
	},
	ca: {
		translation: {
			running: "En procés...",
			loading: "Carregant...",
			noChannels: "No s'ha trobat cap canal",
			noChannelsFound: "No s'ha trobat cap canal",
			button: {
				"close": "Tancar",
				"ok": "D'acord",
			},
			menu: {
				home: "Inici",
				channels: "Canals",
				addChannel: "Afegir canal",
				settings: "Configuració",
			},
			openYT: "Obrir a YouTube",
			channel: {
				videos: "vídeos",
				subscribers: "subscriptors",
				views: "visualitzacions",
				datePublished: "A YouTube des de:",
				dateLastIndexed: "Última actualització:",
				ytVideoCount: "Número de vídeos a YouTube:",
				subscriberCount: "Número de subscriptors:",
				viewCount: "Número de visualitzacions:",
				indexChannel: "Afegir aquest canal a TrobaTube",
				index: {
					title: "Obtenir informació i llista de vídeos",
					message: "Segur que vols afegir aquest canal a TrobaTube amb la informació que hi ha a YouTube? Aquest procés pot trigar alguns segons o minuts depenent del nombre de vídeos del canal.",
					"message-long": "<br/>Aquest canal té més de 500 vídeos. Només s'afegiran els 500 vídeos més recents.<br/>Si ets el propietari del canal i vols veure tots els vídeos, si us plau, posa't en contacte amb nosaltres a <a mailto='hola@trobatube.com' target='_blank'>hola@trobatube.com</a>",
					start: "Començar",
					error: "Alguna cosa no ha anat bé. Si us plau, contacta amb suport o torna-ho a intentar més tard."
				},
				reindexChannel: "Actualitzar aquest canal ara",
				reindex: {
					title: "Actualizar informació i llista de vídeos",
					message: "Segur que vols actualitzar aquest canal amb la informació que hi ha ara a YouTube? Aquest procés pot trigar alguns segons o minuts depenent del nombre de vídeos del canal.",
					"message-long": "<br/>Aquest canal té més de 500 vídeos. Només s'actualitzaran els 500 vídeos més recents.<br/>Si ets el propietari del canal i vols veure tots els vídeos, si us plau, posa't en contacte amb nosaltres a <a mailto='hola@trobatube.com' target='_blank'>hola@trobatube.com</a>",
					start: "Començar l'actualització ara",
				},
			},
			mode: {
				dark: "Mode fosc",
				light: "Mode clar",
			},
			dark: "Fosc",
			light: "Clar",
			video: {
				datePublished: "Publicat el ",
				views: "visualitzacions",
				likes: "likes",
				reindexVideo: "Actualitzar aquest vídeo ara",
				reindex: {
					title: "Actualitzar",
					message: "Segur que vols actualitzar la informació d'aquest vídeo amb la informació que hi ha ara a YouTube?",
				}
			},
			order: {
				by: "Ordenar per",
				asc: "Creixent",
				desc: "Decreixent",
				title: {
					asc: "A-Z",
					desc: "Z-A",
				},
				description: {
					asc: "A-Z",
					desc: "Z-A",
				},
				datePublished: {
					asc: "Més antics primer",
					desc: "Més recents primer",
				},
				duration: {
					asc: "Més curts primer",
					desc: "Més llargs primer",
				},
				viewCount: {
					asc: "Amb menys visualitzacions primer",
					desc: "Amb més visualitzacions primer",
				},
				likeCount: {
					asc: "Menys populars primer",
					desc: "Més populars primer",
				},
				"by-title": "Títol",
				"by-description": "Descripció",
				"by-datePublished": "Data de publicació",
				"by-duration": "Durada",
				"by-viewCount": "Visualitzacions",
				"by-likeCount": "Likes",
			},
			search: {
				'open-advanced': "Cerca avançada",
				'open-simple': "Cerca simple",
				label: "",
				button: "Cercar",
				placeholder: "Cerca...",
				placeholder2: "Text a cercar",
				placeholderNumber: "Indica un número",
				placeholderTime: "Format HH:MM:SS",
				placeholderDate: "Format AAAA-MM-DD",
				clear: "Netejar camps",
				go: "Cercar",
				numFound: "Trobats {{1}} vídeos",
				numFoundTotal: "Trobats {{1}} vídeos (total: {{2}})",
			},
			serchOp: {
				containing: "Amb el text",
				notContaining: "Sense el text",
				starting: "Començant per",
				ending: "Acabat en",
				exact: "Exactament igual a",
				bigger: "Més de",
				smaller: "Menys de",
				before: "Abans de",
				after: "Després de",
				between: "Entre",
				and: "i",
			},
			addChannelPage: {
				indexThisChannel: "Afegeix aquest canal",
				alreadyIndexed: "Aquest canal ja és a TrobaTube (actualitzat el {{1}})",
				gotoChannelPage: "Anar a la pàgina del canal",
				getMoreData: "Obtenir més informació de YouTube",
			},
			searchYTChannel: {
				label: "Indica el nom o l'ID del canal que vols afegir. Cercarem aquest canal a YouTube.",
				button: "Cercar",
				placeholder: "Nom o ID del canal",
				noChannels: "No s'han trobat canals amb aquest criteri de cerca",
			},
			settings: {
				currentViewMode: "Mode actual:",
				currentLanguage: "Llengua actual:",
				switchToLightMode: "Canviar a mode clar",
				switchToDarkMode : "Canviar a mode fosc",
			},
			footer: {
				text1: "Última actualització: Setembre 2022",
				text2: "TrobaTube",
				text3: "Contacta amb nosaltres a <a mailto='hola@trobatube.com' target='_blank'>hola@trobatube.com</a>",
			},
		},
	},
};

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
}

i18n
	.use(LanguageDetector)
	.use(initReactI18next)

	.init({
		detection: options,
		//lng: getConfig('initialLanguage'),
    	fallbackLng: getConfig('fallbackLanguage'),
		resources,
		supportedLngs: ['ca', 'en', 'es'],
		interpolation: {
			escapeValue: false,
		},
		//debug: true,
	});

export {i18n};

export function t(code, stringToReplace, stringToReplace2, stringToReplace3) {
	var prefix = [''];
	var _txt = i18n.t(prefix.map((p) => p + code));
	//var _txt = _i18n[code];
	if (typeof stringToReplace !== 'undefined') {
		_txt = _txt.replace('%1', stringToReplace);
	}
	if (typeof stringToReplace2 !== 'undefined') {
		_txt = _txt.replace('%2', stringToReplace2);
	}
	if (typeof stringToReplace3 !== 'undefined') {
		_txt = _txt.replace('%3', stringToReplace3);
	}
	return (window.debugi18n ? '[' + code + '] ' : '') + _txt;
}
