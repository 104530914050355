import React, {useContext} from "react";
import {AppContext} from "../AppContext.js";
import './AppButton.scss';
import { Oval } from 'react-loading-icons'

export function AppButton(props) {
	const {label, onClick, disabled, busy, style, size, buttonType, buttonId} = props;
	const appContext = useContext(AppContext);


	return (
		<div className={"appButton" + (style ? " appButton-" + style : "") + (size ? " appButton-" + size : "")}>
			<button 
				onClick={onClick}
				disabled={disabled}
				type={buttonType}
				id={buttonId}
			>
				{busy && <Oval className="appButtonIcon" stroke={appContext.DM ? "white" : "black"} strokeOpacity={1} speed={1} />}
				{label}
			</button>
		</div>
	);
}
