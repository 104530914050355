import React from "react";
import {formatInteger, timeToDateString, timeToString} from '../services/utils.js';
import {getConfig} from '../config.js';
import { useTranslation } from "react-i18next";
import {ChannelImage} from "../pieces/ChannelImage.js";
import './ChannelDetails.scss';

export function ChannelDetails(props) {
	let channelData = props.channelData;
	const { t, i18n } = useTranslation();

	return (
		<div className="channelDetails">
			<div className="appRow channelHeader">
				<div className="appColumn channelLogo">
					<ChannelImage data={channelData} />
				</div>
				<div className="appColumn">
					<div className="floatItemRight appLink appLinkButton appLinkButton-small">
						<a 
							rel="noreferrer"
							target="_blank" 
							href={getConfig('YOUTUBE_CHANNEL_BASE_URL') + channelData['channelId']} 
						>
							{t('openYT')}
						</a>
					</div>
					<div className="appRow channelTitle">{channelData['title']}</div>
					<div className="appRow channelDescription">{channelData['description']}</div>
				</div>
			</div>
			<div className="channelData">
				{channelData['datePublishedTs'] &&
					<div>{t('channel.datePublished')} <span>{timeToDateString(channelData['datePublishedTs'], i18n.language)}</span></div>
				}
				{channelData['ytVideoCount'] &&
					<div>{t('channel.ytVideoCount')} <span>{formatInteger(channelData['ytVideoCount'], i18n.language)}</span></div>
				}
				{channelData['subscriberCount'] &&
					<div>{t('channel.subscriberCount')} <span>{formatInteger(channelData['subscriberCount'], i18n.language)}</span></div>
				}
				{channelData['viewCount'] &&
					<div>{t('channel.viewCount')} <span>{formatInteger(channelData['viewCount'], i18n.language)}</span></div>
				}
				<div className="space" />
				{channelData['dateLastIndexedTs'] && 
					<div>{t('channel.dateLastIndexed')} <span>{timeToString(channelData['dateLastIndexedTs'], i18n.language)}</span></div>
				}
			</div>
		</div>
	);
}
