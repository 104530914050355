import React from "react";
import { useTranslation } from "react-i18next";
import './Footer.scss';

export function Footer(props) {

	const { t } = useTranslation();

	return (
		<div className="footer">
			<div dangerouslySetInnerHTML={{__html:t('footer.text1')}}/>
			<div dangerouslySetInnerHTML={{__html:t('footer.text2')}}/>
			<div dangerouslySetInnerHTML={{__html:t('footer.text3')}}/>
		</div>
	);
}

