import React from "react";
import {timeToDateString, timeToString} from '../services/utils.js';
import {Link} from "react-router-dom";
import {getConfig} from '../config.js';
import { useTranslation } from "react-i18next";
import {ChannelImage} from "../pieces/ChannelImage.js";
import { FaCloudDownloadAlt } from 'react-icons/fa';
import './ChannelItem.scss';

export function ChannelItem(props) {
	let { busy, channelData, getMoreChannelData } = props;
	const { t, i18n } = useTranslation();
	
	return (
		<div className="listItem channelItem appRow">
			<div className="appColumn">
				<ChannelImage data={channelData} />
			</div>
			<div className="appColumn mainData">
				<div className="channelTitle">{channelData['title']}</div>
				<div className="channelDescription">{channelData['description']}</div>
				<div className="channelDetails">
					{channelData['datePublishedTs'] && <div>{t('channel.datePublished')} <span>{timeToDateString(channelData['datePublishedTs'], i18n.language)}</span></div> }
					{channelData['ytVideoCount'] && <div>{channelData['ytVideoCount']} {t('channel.videos')}</div> }
					{channelData['subscriberCount'] && <div>{channelData['subscriberCount']} {t('channel.subscribers')}</div> }
					{channelData['viewCount'] && <div>{channelData['viewCount']} {t('channel.views')}</div> }
					<div className="space" />
					{channelData['dateLastIndexed'] && <div>{t('channel.dateLastIndexed')} <span>{timeToString(channelData['dateLastIndexedTs'], i18n.language)}</span></div> }
				</div>
				{!channelData['idChannel'] && !busy && !channelData['ytVideoCount'] &&
					<div 
						className="appIcon" 
						onClick={(e) => {getMoreChannelData(channelData['channelId'])}}
					>
						<FaCloudDownloadAlt />
						<span>{t('addChannelPage.getMoreData')}</span>
					</div>
				}
				{channelData['idChannel'] && 
					<div>
						{t('addChannelPage.alreadyIndexed', {1: timeToDateString(channelData['dateLastIndexedTs'], i18n.language)})}
						<Link className="appLinkButton appLinkButton-small gotoButton" to={"/channel/" + channelData['channelId']}>
							{t('addChannelPage.gotoChannelPage')}
						</Link>
					</div>
				}
				{!channelData['idChannel'] && 
					<Link className="appLinkButton addChannelButton" to={"/add-channel/" + channelData['channelId']} disabled={busy}>
						{t('addChannelPage.indexThisChannel')}
					</Link>
				}
			</div>
			<div className="appColumn text-right">
				<a
					className="appLinkButton appLinkButton-small"
					target="_blank" 
					rel="noreferrer"
					href={getConfig('YOUTUBE_CHANNEL_BASE_URL') + channelData['channelId']}
				>
					{t('openYT')}
				</a>
			</div>
		</div>
	);
}
