import React, {useContext, useState} from "react";
import {AppContext} from "../AppContext.js";
import {log} from '../services/utils.js';
import {apiCall} from "../services/apiCall.js";
import {AppModal} from "../components/AppModal.js";
import { useTranslation } from "react-i18next";

export function ChannelIndexModal(props) {
	
	const { t } = useTranslation();
    const appContext = useContext(AppContext);
    const {channelId, setIndexing, closeAction, videoCount} = props;
	const [busy, setBusy] = useState(false);

    const handleClose = function (result) {
    	appContext.closeModal();
    	closeAction(result);
    }

    const indexNow = function() {
    	setIndexing(true);
    	setBusy(true);
		let _call = apiCall('/api.php?action=channel/index/' + channelId, {});
		_call.then(function (json) {
			log('channelPage indexChannel got ', json)
			if (json.status === "ok") {
				log("indexing result: ", json);
				handleClose(true);
			}
		}).catch(function (e) {
			log("indexChannel error in ChannelPage! ", e);
			handleClose(false);
		}).then(function () {
			setBusy(false);
			setIndexing(false);
		});
    }

    let message = t('channel.index.message');
	if (videoCount > 500) {
		message += "<p>" + t('channel.index.message-long') + "</p>";
	}

  	return (
  		<AppModal 
  			title={t('channel.index.title')} 
  			message={message} 
  			okAction={indexNow}
  			okLabel={t('channel.index.start')}
  			busy={busy}
  		/>
  	);
}
