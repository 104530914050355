import React from "react";
import {useTranslation} from "react-i18next";

function LanguageSwitcher() {
  const {i18n} = useTranslation();

  return (
    <div className="select">
      <select
        value={i18n.language}
        onChange={(e) => {
        	i18n.changeLanguage(e.target.value); 
        	//window.location.reload();
        	}
        }
      >
        <option value="en">EN</option>
        <option value="es">ES</option>
        <option value="ca">CA</option>
      </select>
    </div>
  );
}

export default LanguageSwitcher;