import React from "react";
import { useTranslation } from "react-i18next";
import './SearchYTChannelsForm.scss';

export function SearchYTChannelsForm(props) {
	//https://www.youtube.com/results?search_query=lain&sp=CAM%253D
	const { t } = useTranslation();
	const handleSearchTerm = (e) => {
		e.preventDefault();
		props.setSearchTerm(e.target.value);
	}

	const searchChannelAction = (e) => {
		e.preventDefault();
		props.searchChannelAction();
	}	

	return (
		<div className="searchYTChannelsForm">
			<form>
				<div className="intro">{t('searchYTChannel.intro')}</div>
				<label>
					<input 
						type="text" 
						name="searchTerm" 
						value={props.searchTerm} 
						onChange={e => {handleSearchTerm(e)}} 
						placeholder={t("searchYTChannel.placeholder")}
					/>
				</label>
				<button onClick={searchChannelAction}>{t('searchYTChannel.button')}</button>
			</form>
		</div>
	);
}