import React, {useContext} from "react";
import {getConfig} from '../config.js';
import {AppContext} from "../AppContext.js";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import './MainMenu.scss';

export function MainMenu(props) {
	
    const appContext = useContext(AppContext);    
	const { t } = useTranslation();

  	return (
  		<div className="mainMenu">
		    <ul>
		    	{getConfig('isDev') &&
		    	<li>
			        <a
			          href={getConfig('backendServer')}
			          target="_blank"
			          rel="noopener noreferrer"
			        >
			          Backend
			        </a>
		        </li>
		    	}
		    	<li>
			        <a
			          href="#"
			          target="_self"
			          rel="noopener noreferrer"
			          onClick={appContext.switchUiMode}
			        >
			          {appContext.DM ? t('mode.light') : t('mode.dark')}
			        </a>
		        </li>
		        <li>
		            <LanguageSwitcher />
		        </li>

		    </ul>
		</div>
  	);
}

