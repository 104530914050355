import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {log} from '../services/utils.js';
import {ChannelIndexModal} from "../layouts/ChannelIndexModal.js";
import {apiCall} from "../services/apiCall.js";
import {ChannelDetails} from "../pieces/ChannelDetails.js";
import {BigMessage} from "../components/BigMessage.js";
import {ErrorMessage} from "../components/ErrorMessage.js";
import {RunningPanel} from "../components/RunningPanel.js";
import {AppContext} from "../AppContext.js";
import { useTranslation } from "react-i18next";
import { FaCloudDownloadAlt } from 'react-icons/fa';

export function AddChannelNow(props) {

	const { channelId } = useParams();
	const { action } = props;
	let navigate = useNavigate();
  
	const { t } = useTranslation();
	const appContext = useContext(AppContext);
	let lastChannelId = false;
	const [running, setRunning] = useState(false);
	const [channelData, setChannelData] = useState(false);
	const [indexing, setIndexing] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);

	useEffect(() => {
		if (channelId !== lastChannelId) {
			loadData();
		}
		lastChannelId = channelId;
		}, [channelId]);	

	function loadData() {
		setErrorMessage(false);
		setRunning(true);
		let _call = apiCall('/api.php?action=channel/YTchannelData/' + channelId, {});
		_call.then(function (json) {
			log('addChannelNow loadBasicData got ', json)
			if (json.data) {
				setChannelData(json.data);
			}
		}).catch(function (e) {
			//setErrorMessage(t('channel.index.error'));
			log("addChannelNow error in ChannelPage! ", e);
		}).then(function () {
			setRunning(false);
		});
	}

	if (running) {
		return (
			<RunningPanel type="full" />
		);
	}

	function showResult(result) {
		//console.log("show result ", result);
		if (!result) {
			setErrorMessage(t('channel.index.error'));
		} else {
			navigate('/channel/' + channelId);
		}
	}

	function showConfirmModal() {
		setErrorMessage(false);
		const indexDialog = (
			<ChannelIndexModal 
				channelId={channelId} 
				setIndexing={setIndexing}
				closeAction={showResult}
				videoCount={channelData['ytVideoCount']}
			/>
		);
		appContext.openModal(indexDialog);
	}

	if (!action || action !== 'add' || !channelId) {
		return (
			<BigMessage text="No channel found" />
		)
	}

	return (
		<div className="addChannelNow appPage">
			{!channelData && 
				<div>
					No channel info
				</div>
			}
			{channelData &&
				<div>
					<ChannelDetails channelData={channelData} />
					<ErrorMessage text={errorMessage} />
					{!indexing && 
					<div>
						<div className="space" />
						<div className="appLink appLinkButton" onClick={showConfirmModal}><FaCloudDownloadAlt /> {t('channel.indexChannel')}</div>
						<div className="space" />
					</div>
					}
					{indexing &&
						<div>Indexing...</div>
					}
				</div>
			}
		</div>
	);
}
