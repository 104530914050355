import React from "react";
import {Link} from "react-router-dom";
import {TTicon} from '../components/TTicon.js';
import { useTranslation } from "react-i18next";
import './ContextMenu.scss';

export function ContextMenu(props) {

	const { t } = useTranslation();

  	return (
  		<div className="contextMenu">
  		{/*}
			<Link to="/">
				<div className="item appLink">
					<TTicon id="home" />{t('menu.home')}
				</div>
			</Link>
		*/}
			<Link to="/channels">
				<div className="item appLink">
					<TTicon id="channels" />{t('menu.channels')}
				</div>
			</Link>
			<Link to="/add-channel">
				<div className="item appLink">
					<TTicon id="add-channel" />{t('menu.addChannel')}
				</div>
			</Link>
			<Link to="/settings">
				<div className="item appLink">
					<TTicon id="settings" />{t('menu.settings')}
				</div>
			</Link>
		</div>
	);
}

