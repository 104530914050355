import React from "react";
import {VideoItem} from "../pieces/VideoItem.js";
import './VideoList.scss';

export function VideoList(props) {
	let list = props.list;

	return (
		<div className="videoList">
			{list.map((item) => {
				return (
					<VideoItem item={item} key={item.idVideo} />
				)
			})}
		</div>
	);
}