import React, {useContext, useEffect, useState} from "react";
import {Link, useParams, useSearchParams} from "react-router-dom";
import {isIntegerValue, log} from '../services/utils.js';
import {ChannelReindexModal} from "../layouts/ChannelReindexModal.js";
import {apiCall} from "../services/apiCall.js";
import {RunningPanel} from "../components/RunningPanel.js";
import {ChannelDetails} from "../pieces/ChannelDetails.js";
import {SearchVideosForm} from "../pieces/SearchVideosForm.js";
import {AdvancedSearchVideosForm} from "../pieces/AdvancedSearchVideosForm.js";
import {SortVideosForm} from "../pieces/SortVideosForm.js";
import {VideoList} from "../pieces/VideoList.js";
import {AppButton} from "../components/AppButton.js";
import {AppContext} from "../AppContext.js";
import {getConfig} from '../config.js';
import { useTranslation } from "react-i18next";
import './ChannelPage.scss';
import { FaCloudDownloadAlt } from 'react-icons/fa';



export function ChannelPage(props) {

	const { t } = useTranslation();
	const appContext = useContext(AppContext);
	const { channelId } = useParams();
	let lastChannelId = false;
	const [running1, setRunning1] = useState(false);
	const [running2, setRunning2] = useState(false);
	const [channelData, setChannelData] = useState(false);
	const [channelVideoList, setChannelVideoList] = useState([]);
	const [videoOrderField, setVideoOrderField] = useState(getConfig('VIDEO_ORDER_FIELD_DEFAULT'));
	const [videoOrderType, setVideoOrderType] = useState(getConfig('VIDEO_ORDER_TYPE_DEFAULT'));
	const [searchTerm, setSearchTerm] = useState("");      // for simple search
	const [appSearchParams, setAppSearchParams] = useState(null);  // for advanced search
	const [numFound, setNumFound] = useState(0);
	const [reindexing, setReindexing] = useState(false);
	const [searchMode, setSearchMode] = useState('simple');

	useEffect(() => {
		if (channelId !== lastChannelId) {
			loadData();
		}
		lastChannelId = channelId;
		}, [channelId]);

	useEffect(() => {
    	const num = document.getElementsByClassName('listItem').length;
    	setNumFound(num);
  	}, [searchTerm]);
		
	useEffect(() => {
    	const num = document.getElementsByClassName('listItem').length;
    	setNumFound(num);
  	}, [appSearchParams]);

	function getCurrentNumFound() {
		return document.getElementsByClassName('listItem').length;
	}

	function loadData() {
		loadBasicData();
		loadListData();
	}

	function loadBasicData() {
		setRunning1(true);
		let _call1 = apiCall('/api.php?action=channel/info/' + channelId, {});
		//let _call = apiCall('/channel/get', {channelId: channelId});
		Promise.all([_call1])
		.then(function (values) {
			log('channelPage loadBasicData got ', values)
			var json1 = values[0];

			if (json1.data) {
				setChannelData(json1.data);
			}

		}).catch(function (e) {
				log("loadBasicData error in ChannelPage! ", e);
		}).then(function () {
			setRunning1(false);
		});
	}

	function loadListData() {
		setRunning2(true);
		let _call2 = apiCall('/api.php?action=channel/videos/' + channelId, {});
		//let _call = apiCall('/channel/get', {channelId: channelId});
		Promise.all([_call2])
		.then(function (values) {
			log('channelPage loadListData got ', values)
			var json2 = values[0];
			if (json2.data) {
				setChannelVideoList(json2.data);
			}

		}).catch(function (e) {
				log("loadListData error in ChannelPage! ", e);
		}).then(function () {
			setRunning2(false);
		});
	}

	function getVideoListItems() {
		let list = (channelVideoList && channelVideoList.items) ? channelVideoList.items : [];
		let orderField = videoOrderField;
		if (orderField === "datePublished") {
			orderField = "datePublishedTs";
		} else if (orderField === "duration") {
			orderField = "durationSeconds";
		}

		list.sort((a, b) => {
			let aVal = a[orderField];
			let bVal = b[orderField];
			if (isIntegerValue(aVal)) {aVal = parseInt(aVal)}
			if (isIntegerValue(bVal)) {bVal = parseInt(bVal)}
			let returnValue = 0;
			if (videoOrderType === 'ASC') {
				returnValue = aVal > bVal ? 1 : -1;
			} else {
				returnValue = aVal < bVal ? 1 : -1;
			}
			return returnValue;
		});
		if (searchTerm) {
			list = list.filter((item) => {
				return item['title'].toUpperCase().includes(searchTerm.toUpperCase())
			});
		} else if (appSearchParams) {
			list = list.filter((item) => {
				return checkVideoInFilter(item);
			});
		}
		return list;
	}

	if (running1) {
		return (
			<RunningPanel type="full" />
		);
	}

	function checkVideoInFilter(item) {
		let theField = appSearchParams['field'];
		const fieldType = getFieldTypeForField(theField);
		if (theField === "duration") {
			theField = "durationSeconds"
		}
		const theOp = appSearchParams['op'];
		let theTerm = appSearchParams['term'];
		const theTerma = appSearchParams['terma'];
		let fieldValue = item[theField];
		let check = false;
		switch (fieldType) {
			case "text":
				fieldValue = fieldValue.toUpperCase();
				theTerm = theTerm.toUpperCase();
				switch (theOp) {
					case "containing": 
						check = fieldValue.includes(theTerm);
						break;
					case "notContaining":
						check = !fieldValue.includes(theTerm);
						break;
					case "starting":
						check = fieldValue.startsWith(theTerm);
						break;
					case "ending":
						check = fieldValue.endsWith(theTerm);
						break;
					case "exact":
						check = fieldValue === theTerm;
						break;
					default:
						check = false;
				}
				break;
			case "date":
				// The same length as theTerm
				let fieldValueTruncated = fieldValue;
				if (theTerm.length > 1) {
					fieldValueTruncated = fieldValue.substring(0, (theTerm.length));
				}
				switch (theOp) {
					case "exact": 
						check = fieldValueTruncated === theTerm;
						break;
					case "before": 
						check = fieldValueTruncated < theTerm;
						break;
					case "after":
						check = fieldValueTruncated > theTerm;
						break;
					case "between":
						check = fieldValueTruncated >= theTerm && fieldValueTruncated <= theTerma;
						break;
					default:
						check = false;
				}
				break;
			case "time":
				let theTermSeconds = getDurationInSeconds(theTerm)
				console.log("comparant value ", fieldValue, theTermSeconds );
				switch (theOp) {
					case "bigger": 
						check = parseInt(fieldValue) > parseInt(theTermSeconds);
						break;
					case "smaller":
						check = parseInt(fieldValue) < parseInt(theTermSeconds);
						break;
					case "exact":
						check = parseInt(fieldValue) === parseInt(theTermSeconds);
						break;
					default:
						check = false;
				}
				break;
			case "number":
				switch (theOp) {
					case "bigger": 
						check = parseInt(fieldValue) > parseInt(theTerm);
						break;
					case "smaller":
						check = parseInt(fieldValue) < parseInt(theTerm);
						break;
					case "exact":
						check = parseInt(fieldValue) === parseInt(theTerm);
						break;
					default:
						check = false;
				}
				break;
			default:
				check = false;
		}
		return check;
	}

	/**
	 * duration can be seconds or HH:MM:SS
	 **/
	function getDurationInSeconds(duration) {
		let seconds = duration;
		let parts = duration.split(':');
		if (parts.length === 3) {
			seconds = parts[0] * 3600 + parts[1] * 60 + parts[2] * 1;
		} else if (parts.length === 2) {
			seconds = parts[0] * 60 + parts[1] * 1
		}
		return seconds;
	}

	function getTotalVideoCount() {
		return channelVideoList && channelVideoList.items && channelVideoList.items.length || "";
	}

	function reindexChannel() {
		const reindexDialog = (
			<ChannelReindexModal 
				channelId={channelId} 
				setReindexing={setReindexing}
				closeAction={loadData}
				videoCount={getTotalVideoCount()}
			/>
		);
		appContext.openModal(reindexDialog);
	}

	function showAdvancedSearchForm() {
		setSearchTerm("");
		setSearchMode('advanced');
	}

	function hideAdvancedSearchForm() {
		setSearchMode('simple');

	}

	function getFieldTypeForField(theField) {
		let theType = "text";
		switch (theField) {
			case "title": case "description": 
				theType = "text";
				break;
			case "datePublished":
				theType = "date";
				break;
			case "duration": 
				theType = "time";
				break;
			case "viewCount": case "likeCount":
				theType = "number";
				break;
			default: 
				theType = "text";
		}
		return theType;
	}

	return (
		<div className="channelPage appPage">
			{!channelData && 
				<div>
				<div>This channel is not indexed yet.</div>
				<Link to={"/add-channel/" + channelId}>Index this channel</Link>
			</div>
		}
		{channelData &&
			<div>
				<ChannelDetails channelData={channelData} />
				{!reindexing && 
				<div>
					<div className="space" />
					<div className="appLink appLinkButton" onClick={reindexChannel}><FaCloudDownloadAlt /> {t('channel.reindexChannel')}</div>
					<div className="space" />
					<div className="appRow spaced baseline functionsBar">
						{searchMode === 'simple' &&
						<>
						<div>
							<SearchVideosForm 
								className="appColumn" 
								searchTerm={searchTerm}
								setSearchTerm={setSearchTerm}
							/>
							<AppButton 
								label={t('search.open-advanced')} 
								onClick={showAdvancedSearchForm} 
								style="text"
								size="small"
							/>
						</div>
						<SortVideosForm 
						 	className="appColumn"
							videoOrderField={videoOrderField} 
							setVideoOrderField={setVideoOrderField} 
							videoOrderType={videoOrderType} 
							setVideoOrderType={setVideoOrderType} 
						/>
						</>
						}
						{searchMode === 'advanced' &&
						<div>
							<AdvancedSearchVideosForm 
								className="appColumn" 
								appSearchParams={appSearchParams}
								setAppSearchParams={setAppSearchParams}
								getFieldTypeForField={getFieldTypeForField}
							/>
						</div>
						}						
					</div>
					{searchMode === 'advanced' &&
					<div className="appRow spaced baseline functionsBar">
						<AppButton 
								label={t('search.open-simple')} 
								onClick={hideAdvancedSearchForm} 
								style="text"
								size="small"
							/>
						<SortVideosForm 
						 	className="appColumn"
							videoOrderField={videoOrderField} 
							setVideoOrderField={setVideoOrderField} 
							videoOrderType={videoOrderType} 
							setVideoOrderType={setVideoOrderType} 
						/>
					</div>
					}
					<div>
						{(searchTerm || appSearchParams) && 
							<div className="tiny-text">{t('search.numFoundTotal', {1: numFound, 2: getTotalVideoCount()})}</div>
						}
						{!searchTerm && !appSearchParams &&
							<div className="tiny-text">{t('search.numFound', {1: getTotalVideoCount()})}</div>
						}
					</div>
					{channelVideoList && channelVideoList.items && channelVideoList.items.length &&
						<VideoList list={getVideoListItems()} />
					}
					{running2 && 
						<RunningPanel />
					}
				</div>
				}
				{reindexing &&
					<RunningPanel />
				}
			</div>
		}
	</div>
	);
}
