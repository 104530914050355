import moment from 'moment';
import 'moment-timezone';

export function isDev() {
	return true;
}

export function log(...args) {
	var _output = isDev() || (window.__debug__ === true);
	if (_output) {
		console.log(...args);
	}
}

export function isIntegerValue(val) {
	return ("" + parseInt(val) === "" + val);
}

export function getLocale(language) {
	let theLocale = 'en-GB';
	switch (language) {
		case 'en': theLocale = 'en-GB'; break;
		case 'es': theLocale = 'es-ES'; break;
		case 'ca': theLocale = 'ca-ES'; break;
		default: theLocale = 'en-GB'; break;
	}
	return theLocale;
}

export function formatInteger(number, language) {
	// ex: let num = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);
	let formatter = Intl.NumberFormat(getLocale(language));
	return formatter.format(number);
}

/********** DATE AND TIME FUNCTIONS *************/

// TODO! Get from browser
export function getTimezone() {
	return moment.tz.guess();
}

// TODO! Get from browser!!! 
export function timeToDateString(ts, language) {
	var options = { day: 'numeric', month: 'long', year: 'numeric'};
	return timeToString(ts, language, options);
}

export function timeToSimpleDateString(ts, language) {
	var options = { year: 'numeric', month: 'short', day: '2-digit' };
	return timeToString(ts, language, options);
}

export function timeToString(ts, language, options) {
	/*
	    day:
	    The representation of the day.
	    Possible values are "numeric", "2-digit".
	    weekday:
	    The representation of the weekday.
	    Possible values are "narrow", "short", "long".
	    year:
	    The representation of the year.
	    Possible values are "numeric", "2-digit".
	    month:
	    The representation of the month.
	    Possible values are "numeric", "2-digit", "narrow", "short", "long".
	    hour:
	    The representation of the hour.
	    Possible values are "numeric", "2-digit".
	    minute: The representation of the minute.
	    Possible values are "numeric", "2-digit".
	    second:
	    The representation of the second.
	    Possible values are "numeric", 2-digit".
	*/
	let locale = getLocale(language);
	if (typeof options === 'undefined') {
		options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric'};
	}
	let tz = getTimezone();
	let date = moment.unix(ts).tz(tz);
	//return date.format(format);
	return new Intl.DateTimeFormat(locale, options).format(date);
}

export function formatDuration(stringDuration) {
	let duration = moment.duration(stringDuration);
	let seconds = duration.get('seconds');
	let minutes = duration.get('minutes');
	let hours = duration.get('hours');
	let days = duration.get('days');
	let durationString = days ? days + ' ' : '';
	durationString += hours ? hours + ':' : '';
	durationString += hours && minutes < 10 ? '0' : '';
	durationString += minutes + ':';
	durationString += seconds < 10 ? '0' : '';
	durationString += seconds;

	return durationString;
}