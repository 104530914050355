export function getConfig(arg) {

	const localhostPort = 8082;
	const ENV = process.env.NODE_ENV;  /* production or development */

	const allParams = {
		backendServer:
			(ENV === 'production' && window.location.origin.indexOf('localhost') === -1) ?
			'https://www.trobatube.com/backend' : /* window.location.origin + '/backend' */
			'http://localhost:' + localhostPort + '/backend',
		startWithDarkMode: false, 
		initialLanguage: 'es', 
		fallbackLanguage: 'en', 
		isDev: ENV === 'development' ? true : false,
		YOUTUBE_CHANNEL_BASE_URL: 'https://www.youtube.com/channel/',
		YOUTUBE_VIDEO_BASE_URL: 'https://www.youtube.com/watch?v=',
		VIDEO_ORDER_FIELD_DEFAULT: 'datePublished',
		VIDEO_ORDER_TYPE_DEFAULT: 'DESC',
	};

	if (arg == null) {
		return allParams;
	} else {
		return allParams[arg];
	}
}

window.__config__ = getConfig();

