import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {AppContext} from "../AppContext.js";
import {MainMenu} from '../components/MainMenu.js';
import './MainHeader.scss';
import logoLight from '../assets/img/logo_light.png';
import logoDark from '../assets/img/logo_dark.png';

export function MainHeader(props) {
	
    const appContext = useContext(AppContext);    
    const logo = appContext.DM ? logoDark : logoLight;

	return (
		<header className="mainHeader">
			<Link to="/">
				<img src={logo} className="App-logo" alt="logoText" />
			</Link>
		 	<MainMenu />
		</header>
	);
}

