import React, {useContext} from "react";
import Modal from 'react-bootstrap/Modal';
import {AppContext} from "../AppContext.js";
import {AppButton} from "../components/AppButton.js";
import { useTranslation } from "react-i18next";
import './AppModal.scss';

export function AppModal(props) {
	
	const { t } = useTranslation();
    const appContext = useContext(AppContext);
    const {title, message, okAction, okLabel, busy} = props;
    const show = appContext.openModal;
    const footer = okAction ?
					<AppButton disabled={busy} busy={busy} label={okLabel || t('button.ok')} onClick={okAction} /> :
					false;
	const modalSize = '';  // sm, lg, xl

	const handleClose = function () {
		if (props.onClose) {
			props.onClose();
		}
		appContext.closeModal();
	}
	return (
		<Modal
			dialogClassName={"appModal"  + (appContext.DM ? " darkMode" : "")}
			size={modalSize}
			show={show}
			onHide={handleClose}
			centered={true}
			backdrop="static"
		>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			    <div dangerouslySetInnerHTML={{__html:message}}/>
			</Modal.Body>
			{footer && 
				<Modal.Footer>{footer}</Modal.Footer>
			}
		</Modal>
	);
}

