import React, {useState} from "react";
import {log} from '../services/utils.js';
import {apiCall} from "../services/apiCall.js";
import { useTranslation } from "react-i18next";
import {RunningPanel} from "../components/RunningPanel.js";
import {SearchYTChannelsForm} from "../pieces/SearchYTChannelsForm.js";
import {ChannelItem} from "../pieces/ChannelItem.js";
import './AddChannelPage.scss';

export function AddChannelPage(props) {
	
	const { t } = useTranslation();
	const [running, setRunning] = useState(false);
	const [busy, setBusy] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [channelsList, setChannelsList] = useState(false);
	
	function searchChannel() {
		setRunning(true);
		let _call = apiCall('/api.php?action=channel/YTsearch/' + searchTerm, {});
		//let _call = apiCall('/channel/list', {});
		_call.then(function (json) {
			log('searchChannel got ', json);
			if (json.data && json.data) {
				setChannelsList(json.data);
			}
		}).catch(function (e) {
				log("searchChannel error in AddChannelPage! ", e);
		}).then(function () {
			setRunning(false);
		});
	}

	function updateChannelData(channelId, data) {
		let workingList = channelsList;
		workingList.map((item) => {
			if (item.channelId === channelId) {
				item.ytVideoCount = data.ytVideoCount;
				item.subscriberCount = data.subscriberCount;
				item.viewCount = data.viewCount;
			}
			return item;
		});
		setChannelsList(workingList);
	}

	function getMoreChannelData(channelId) {
		setBusy(true);
		let _call = apiCall('/api.php?action=channel/YTchannelData/' + channelId, {});
		_call.then(function (json) {
			log('getMoreChannelData got ', json);
			if (json.data) {
				updateChannelData(channelId, json.data);
			}
		}).catch(function (e) {
				log("getMoreChannelData error in AddChannelPage! ", e);
		}).then(function () {
			setBusy(false);
		});
	}

	if (running) {
		return (
			<RunningPanel type="full" />
		);
	}

  	return (
  		<div className="addChannelPage appPage">
  			<h3>{t(('menu.addChannel'))}</h3>
  			<SearchYTChannelsForm 
				className="appColumn" 
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
				searchChannelAction={searchChannel}
			/>
			{channelsList && channelsList.length === 0 &&
				<div>{t('noChannelsFound')}</div>
			}
			{channelsList && channelsList.length > 0 && 
				<div className="list">
				{channelsList.map((item) => {
					return (
						<ChannelItem 
							key={item.channelId}
							channelData={item} 
							getMoreChannelData={getMoreChannelData}
							busy={busy}
						/>						
					)
				})
				}
				</div>
			}
		</div>
  	);
}

