import React, {useState} from "react";
import {formatDuration, formatInteger, log, timeToString} from '../services/utils.js';
import {apiCall} from "../services/apiCall.js";
import {getConfig} from "../config.js";
import { useTranslation } from "react-i18next";
/*import ReactTooltip from 'react-tooltip';*/
import './VideoItem.scss';
import { FaCloudDownloadAlt } from 'react-icons/fa';

export function VideoItem(props) {
	const { t, i18n } = useTranslation();
	const [currentItem, setCurrentItem] = useState(props.item);
	const [reindexing, setReindexing] = useState(false);
	const [tooltip, showTooltip] = useState(true);

	function parseVideoTitle(text) {
		return text.replaceAll("&quot;", '"');
	}

	function getViewsText(num) {
		return formatInteger(num, i18n.language) + " " + t('video.views');
	}

	function getLikesText(num) {
		return formatInteger(num, i18n.language) + " " + t('video.likes');
	}

	/**
	 * Updates the data shown
	 **/
	function refreshData(data) {
		setCurrentItem(data);
	}

	function reindexVideo(videoId) {
		let goAhead = window.confirm(t('video.reindex.message'));
		if (goAhead) {
			setReindexing(true);
			let _call = apiCall('/api.php?action=video/reindex/' + videoId, {});
			//let _call = apiCall('/channel/reindex', {channelId: channelId});
			_call.then(function (json) {
				log('VideoList reindexVideo got ', json)
				if (json.status === "ok") {
					refreshData(json.data);
				}
			}).catch(function (e) {
					log("reindexVideo error in VideoList! ", e);
			}).then(function () {
				setReindexing(false);
			});

		}
	}

	let item = currentItem;

	return (
		<div className="videoItem listItem" key={item.idVideo}>
			{/* tooltip && <ReactTooltip effect="solid" place="left" scrollHide="true" /> */}
			{reindexing && 
				<div>{t('updating')}</div>
			}
			{!reindexing && 
			<div className="appRow full-row">
				<div className="appColumn videoImg">
					<a 
						target="_blank" 
						rel="noreferrer"
						href={getConfig('YOUTUBE_VIDEO_BASE_URL') + item.videoId} 
						data-tip={t('openYT')}
						onMouseEnter={() => showTooltip(true)}
						onMouseLeave={() => {
							showTooltip(false);
							setTimeout(() => showTooltip(true), 50);
						}}
					>
						<img src={item.thumbnailUrl} alt="" />
					</a>
					<div className="duration">{formatDuration(item.duration)}</div>
				</div>
				<div className="appColumn">
					<div className="videoTitle">{parseVideoTitle(item.title)}</div>
					<div className="videoDescription">{item.description}</div>
					<div className="videoStatistics">
						<div>{t('video.datePublished')} <span>{timeToString(item.datePublishedTs, i18n.language)}</span></div>
						{item.viewCount &&
							<div><span>{getViewsText(item.viewCount)}</span></div>
						}
						{item.likeCount && 
							<div><span>{getLikesText(item.likeCount)}</span></div>
						}
					</div>
				</div>
				<div className="appColumn text-right">
					<div 
						className="appIcon" 
						onClick={(e) => {reindexVideo(item.videoId)}}><FaCloudDownloadAlt 
						data-tip={t('video.reindexVideo')}
					/>
					</div>
				</div>
			</div>
			}
		</div>
	);
}