import React from "react";
import './BigMessage.scss';

export function BigMessage(props) {
	
	const text = props.text;

	return (
		<div className="bigMessage">{text}</div>
	);
}

