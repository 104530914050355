import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {AppButton} from "../components/AppButton.js";
import { useTranslation } from "react-i18next";
import './AdvancedSearchVideosForm.scss';

export function AdvancedSearchVideosForm(props) {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const {getFieldTypeForField, setAppSearchParams} = props;
	const defaultSearchField = "title";
	const defaultSearchOp = "containing";
	const defaultSearchTerm = "";
	const defaultSearchOpType = "text";

	const firstSearchOp = {
		'text': 'containing',
		'date': 'exact',
		'time': 'bigger',
		'number': 'bigger',
	}

	let searchFieldValue = searchParams.get("sf") || defaultSearchField;
	let searchOpValue = searchParams.get("so") || defaultSearchOp;
	let searchTermValue = searchParams.get("tv") || defaultSearchTerm;
	let searchTermaValue = searchParams.get("tva") || defaultSearchTerm;

	const [searchField, setSearchField] = useState(searchFieldValue);
	const [searchOp, setSearchOp] = useState(searchOpValue);
	const [searchTerm, setSearchTerm] = useState(searchTermValue);
	const [searchTerma, setSearchTerma] = useState(searchTermaValue); // only for dates
	const [searchOpType, setSearchOpType] = useState(defaultSearchOpType);

	const checkKeyPress = (e) => {
		const { key, keyCode } = e;
		if (keyCode === 13) {
			// enter
			startSearch(e);
		}
	};

	const handleSearchFieldChange = (e) => {
		e.preventDefault();
		let searchFieldValue = e.target.value
		setSearchField(searchFieldValue);

		// change available searchOp according to search field type
		changeAvailableSearchOps(searchFieldValue);	
	}

	const changeAvailableSearchOps = (searchFieldValue) => {
		const theType = getFieldTypeForField(searchFieldValue);
		setSearchOpType(theType);
		// reset current searchOp
		setSearchOp(firstSearchOp[theType]);
	}

	const handleSearchOpChange = (e) => {
		e.preventDefault();
		setSearchOp(e.target.value);
	}

	const handleSearchTermChange = (e) => {
		e.preventDefault();
		setSearchTerm(e.target.value);
	}

	// only for dates
	const handleSearchTermaChange = (e) => {
		e.preventDefault();
		setSearchTerma(e.target.value);
	}

	const clearForm = (e) => {
		e.preventDefault();
		setSearchField(defaultSearchField);
		handleSearchFieldChange(e);
		setSearchOp(defaultSearchOp);
		handleSearchOpChange(e);
		setSearchTerm(defaultSearchTerm);
		handleSearchTermChange(e);
		setAppSearchParams(null);
	}

	const startSearch = (e) => {
		e.preventDefault();
		let appSearchParams = {
			'field': searchField,
			'op': searchOp,
			'term': searchTerm,
			'terma': searchTerma,
		}
		setAppSearchParams(appSearchParams);
	}

	const getPlaceholder = () => {
		let placeholder = t("search.placeholder2");
		switch (searchOpType) {
			case "time": 
				placeholder = t("search.placeholderTime");
				break;
			case "date": 
				placeholder = t("search.placeholderDate");
				break;
			case "number":
				placeholder = t("search.placeholderNumber");
				break;
			default: 
				placeholder = t("search.placeholder2");				
		}
		return placeholder;
	}

	let placeholder = getPlaceholder();
	 
	switch (searchOpType) {

	}
	return (
		<div className="advancedSearchVideosForm">
			<div>
				<div className="appRow fieldRow">
					<select name="searchField" id="searchField" value={searchField} onChange={e => {handleSearchFieldChange(e)}}>
						<option value="title">{t('order.by-title')}</option>
						<option value="description">{t('order.by-description')}</option>
						<option value="datePublished">{t('order.by-datePublished')}</option>
						<option value="duration">{t('order.by-duration')}</option>
						<option value="viewCount">{t('order.by-viewCount')}</option>
						<option value="likeCount">{t('order.by-likeCount')}</option>
					</select>
					{searchOpType == 'text' &&
						<select name="searchOp" id="searchOp" value={searchOp} onChange={e => {handleSearchOpChange(e)}}>
							<option value="containing">{t('serchOp.containing')}</option>
							<option value="notContaining">{t('serchOp.notContaining')}</option>
							<option value="starting">{t('serchOp.starting')}</option>
							<option value="ending">{t('serchOp.ending')}</option>
							<option value="exact">{t('serchOp.exact')}</option>
						</select>
					}
					{searchOpType == 'date' &&
						<select name="searchOp" id="searchOp" value={searchOp} onChange={e => {handleSearchOpChange(e)}}>
							<option value="exact">{t('serchOp.exact')}</option>
							<option value="before">{t('serchOp.before')}</option>
							<option value="after">{t('serchOp.after')}</option>
							<option value="between">{t('serchOp.between')}</option>
						</select>
					}
					{searchOpType == 'time' &&
						<select name="searchOp" id="searchOp" value={searchOp} onChange={e => {handleSearchOpChange(e)}}>
							<option value="bigger">{t('serchOp.bigger')}</option>
							<option value="smaller">{t('serchOp.smaller')}</option>
							<option value="exact">{t('serchOp.exact')}</option>
						</select>
					}
					{searchOpType == 'number' &&
						<select name="searchOp" id="searchOp" value={searchOp} onChange={e => {handleSearchOpChange(e)}}>
							<option value="bigger">{t('serchOp.bigger')}</option>
							<option value="smaller">{t('serchOp.smaller')}</option>
							<option value="exact">{t('serchOp.exact')}</option>
						</select>
					}
					<input 
						type="text" 
						name="searchTerm" 
						value={searchTerm} 
						onChange={e => {handleSearchTermChange(e)}} 
						onKeyDown={checkKeyPress}
						placeholder={placeholder}
					/>
					{searchOpType === "date" && searchOp === "between" && 
						<>
						<div className="space-right">{t('serchOp.and')}</div>
						<input 
							type="text" 
							name="searchTerma" 
							value={searchTerma} 
							onChange={e => {handleSearchTermaChange(e)}} 
							onKeyDown={checkKeyPress}
							placeholder={placeholder}
						/>
						</>
					}
				</div>
				<div className="appRow-spaced">
					<AppButton onClick={clearForm} label={t("search.clear")} style="text" buttonId="clearButton" />
					<AppButton onClick={startSearch} label={t("search.go")} type="submit" />
				</div>
			</div>
		</div>
	);
}